import React from 'react'
import stylesheet from './index.module.less'

export default (props) => {
    const {english = '', title = '', content = ''} = props
    return (
        <div className={stylesheet.container}>
            <div className={stylesheet.english}>{english}</div>
            <div className={stylesheet.title}>{title}</div>
            <div className={stylesheet.content}>{content}</div>
        </div>
    )
}