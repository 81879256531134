import React from 'react'

import style from './index.module.less'

export default () => {
    return (
        <div className={style.banner_bg}>
            <img className={style.logo} src='https://parent.ponyrunning.com/activity/20210424/logo_pc.png' />
            <div className={style.title}>小马快跑青少国际教育</div>
            <div className={style.englash_title}>Pony Junior International Education</div>
            <div className={style.explore}>Explore the world</div>
        </div>
    )
}