import React, {useState, useEffect} from 'react';
import {Carousel, WingBlank} from 'antd-mobile'

import swiper_style from './swiper.module.less'

const Swiper = (props) => {
    let [titleList, setTitleList] = useState([{left:'',right:''},{left:'',right:''}])
    let [index, setIndex] = useState(0)
    useEffect(() => {
        const {title = []} = props
        setTitleList(title)
    },[props])

    let {style = {} ,content = []} = props
    style = {...style, boxSizing: 'border-box', height: "100%"}
    return (
        <div>
            <div className={swiper_style.slide_title}>
                <div className={swiper_style.title_left}>
                    {titleList[index]['left']}
                </div>
                <div className={swiper_style.title_right}>
                    {titleList[index]['right']}
                </div>
            </div>
            <WingBlank
                className={swiper_style.swiper_container}
            >
                <Carousel
                    autoplay={false}
                    style={style}
                    dots={true}
                    infinite
                    dotStyle={{
                        background:'rgba(218, 218, 218, 0.45)',
                        width: 10,
                        height: 10,
                        display: 'block',
                        borderRadius: '50%',
                        marginLeft:5,
                        marginRight: 5
                    }}
                    dotActiveStyle={{
                        background: '#ffffff',
                        width: 10,
                        height: 10,
                        display: 'block',
                        borderRadius: '50%',
                        marginLeft:5,
                        marginRight: 5
                    }}
                    afterChange={current => setIndex(current)}
                >
                    {
                        content.map((item, index) => {
                            return (
                                <div key={index}>{item.text}</div>
                            )
                        })
                    }
                </Carousel>
        </WingBlank>
      </div>
    )
}

export default Swiper;