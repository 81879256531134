import React from 'react'
import Text from '../Text'
import stylesheet from './index.module.less'

export default () => {
    return (
        <div className={stylesheet.container}>
            <Text
                english={<div style={{color:'white'}}>UNIQUE TEACHING RESOURCES</div>}
                title={<div style={{color:'white'}}>独家自研教材 国际课程无缝衔接</div>}
                content={<div style={{color:'white'}}>主流国际课程。整编系统严谨、易学易用的系列化独有教材。与学生水平发展更匹配的教学节奏，由浅入深的螺旋式的教材编排，循序渐进地夯实孩子们的学术能力基础，培养深层解决问题的思维能力。</div>}
            />
            <div className={stylesheet.img_container}>
                <img style={{width:'50%'}} src='https://parent.ponyrunning.com/activity/20210424/team_show_pc.png' />
            </div>
        </div>
    )
}