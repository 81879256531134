import React, {useState} from 'react'
import stylesheet from './index.module.less'

export default (props) => {
    const {left_up, left_down, right_down, right_up, index} = props

    let [animation, setAnimation] = useState(false)

    const enter = () => {
        setAnimation(true)
    }
    const leave = () => {
        setAnimation(false)
    }
    const handleClick = () => {
        const {handleClick} = props
        handleClick(index)
    }
    return (
        <div style={{position: 'relative'}} onMouseLeave={leave} onClick={handleClick}>
            <div className={`${stylesheet.container} ${animation ? stylesheet.enter : stylesheet.leave}`} onMouseEnter={enter}>
                <div className={stylesheet.up}>
                    <div className={stylesheet.up_left}>{left_up}</div>
                    <div className={stylesheet.up_right}>{right_up}</div>
                </div>
                <div className={stylesheet.down}>
                    <div className={stylesheet.down_left}>{left_down}</div>
                    <div className={stylesheet.down_right}>{right_down}</div>
                </div>
            </div>
            <div className={`${stylesheet.click_show} ${animation ? stylesheet.click_show_enter : stylesheet.click_show_leave}`}>点击查看</div>
        </div>
    )
}