import React from 'react'
import stylesheet from './index.module.less'

export default () => {
    const team = [
        {title: '网罗全球名校精英', content: 'Excellent teachers'},
        {title: '长期教学经验', content: 'Long term teaching'},
        {title: '专业教学技巧', content: 'Interdisciplinary knowledge'},
        {title: '完善的备课&教案', content: 'Teaching Plan and Skills'},
        {title: '胜人一筹的好评率', content: 'Excelling positive reviews'},
    ]
    return (
        <div className={stylesheet.container}>
            <div className={stylesheet.left_container}>
                <div className={stylesheet.img_container}>
                    <img className={stylesheet.img} src='https://parent.ponyrunning.com/activity/20210424/team.png' />
                </div>
            </div>
            <div className={stylesheet.right_container}>
                {
                    team.map(item => (
                        <div key={item.title} className={stylesheet.item_container}>
                            <div className={stylesheet.item_point}></div>
                            <div className={stylesheet.item_title_content_container}>
                                <div className={stylesheet.item_title}>{item.title}</div>
                                <div className={stylesheet.item_content}>{item.content}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}