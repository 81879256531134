import React from 'react'
import stylesheet from './index.module.less'

const Item = (props) => {
    const {style = {}, img_src = '', title = '', content = '', position = 'left'} = props
    return (
        <div style={style} className={`${stylesheet.container} ${position == 'left' || position == 'right' ? stylesheet.left_right : stylesheet.up_down}`}>
            {
                position == 'left' || position == 'up' ? 
                <>
                    <img className={position == 'up' ? stylesheet.img_up_down : stylesheet.img_left_right} src={img_src} />
                    <div style={{width:20,height:20}}></div>
                </>
                : null
            }
            <div className={stylesheet.title_content_container}>
                <div className={stylesheet.title}>{title}</div>
                <div className={stylesheet.content}>{content}</div>
            </div>
            {
                position == 'right' || position == 'down' ?
                <>
                    <div style={{width:20,height:20}}></div>
                    <img className={position == 'right' ? stylesheet.img_left_right : stylesheet.img_up_down} src='https://parent.ponyrunning.com/activity/20210424/review.png' />
                </>
                : null
            }
        </div>
    )
}

export default () => {
    const list = [
        {
            img_src: 'https://parent.ponyrunning.com/activity/20210424/review.png',
            title: 'Review Learning 温故知新：',
            content: '课前预习+课中强化+课后练习三重学习结构，巩固基础温故知新，完成专属学习笔记。',
            position: 'left',
            style:{
                left:'0%',
                top:'0%'
            }
        },
        {
            img_src: 'https://parent.ponyrunning.com/activity/20210424/enrich.png',
            title: 'Enrich Teaching 赋能教学：',
            content: '模块化系统教学，专注深化领域，为学生匹配精准的指导建议，老师更专业，学生提升更快。',
            position: 'left',
            style:{
                left: '55%',
                top: '5%'
            }
        },
        {
            img_src: 'https://parent.ponyrunning.com/activity/20210424/ability.png',
            title: 'Ability Testing 能力测试 ：',
            content: '入学能力测试+课前课中定期测试+周期性评估测试，从量化的角度把控学生的学习进程。',
            position: 'left',
            style:{
                left:'20%',
                top: '35%'
            }
        },
        {
            img_src: 'https://parent.ponyrunning.com/activity/20210424/yearn.png',
            title: 'Yearn For Improvement 渴望进步： ',
            content: '培养学生的学习内驱力，及时调整难度挑战，通过阶段性的成就，养成对进步的渴望，同时培养其自主学习的能力。',
            position: 'left',
            style:{
                bottom:'0%',
                left:'4%'
            }
        },
        {
            img_src: 'https://parent.ponyrunning.com/activity/20210424/develop.png',
            title: 'Develop Tracking 成长记录 ：',
            content: '实时反馈学习情况与指导建议，让进步可见。关注家校互联，形成双向良性沟通，定期输出学习总结报告，共同助力学生学业。',
            position: 'up',
            style:{
                right: '8%',
                bottom: '8%'
            }
        }
    ]
    return (
        <div className={stylesheet.footer}>
            {
                list.map(item => (
                    <Item 
                        key={item.img_src}
                        img_src={item.img_src}
                        title={item.title}
                        content={item.content}
                        position={item.position}
                        style={item.style}
                    />
                ))
            }
            <img className={stylesheet.footer_point} src='https://parent.ponyrunning.com/activity/20210424/footer_point.png' />
        </div>
    )
}