const pony_api = '/pony_api'
const api = '/api'
const activities = '/activities'
const juniors = '/juniors'

const sms = `${api}/sms`
const apply = `${api}${activities}${juniors}/apply`

export default {
    sms,
    apply
}