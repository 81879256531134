import React, {useState} from 'react'
import stylesheet from './index.module.less'

export default () => {
    let [src, setSrc] = useState('https://parent.ponyrunning.com/activity/20210424/submit.png')

    const down = () => {
        setSrc('https://parent.ponyrunning.com/activity/20210424/submit_active.png')
    }
    const up = () => {
        setSrc('https://parent.ponyrunning.com/activity/20210424/submit.png')
        window.$('html, body').animate({scrollTop: window.$('#form').offset().top}, 1000)
    }
    return (
        <div className={stylesheet.container}>
            <div className={stylesheet.text_img_container}>
                <div>
                    <p className={stylesheet.title}>权威测评 快速提分</p>
                    <p className={stylesheet.content}>众多海外院校鼎力推荐，顶尖学府专用测评系统</p>
                </div>
                <div>
                    <img onMouseDown={down} onMouseUp={up} className={stylesheet.img} src={src} />
                </div>
            </div>
        </div>
    )
}