import { Button, DatePicker, Select, message } from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import React,{useCallback, useState} from 'react'
import stylesheet from './index.module.less'
import axios from '../../../../http/http'
import url from '../../../../http/url'

import moment from 'moment'

const Option = Select.Option


const isPhoneNum = (str) => { // 验证手机号
    return /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/.test(str)
}

const Code = props => {
    let [bg_color, setBgColor] = useState('#121851')
    let [text, setText] = useState('获取验证码')

    const handleGetCode = useCallback(()=>{
        const {mobile} = props
        if(!isPhoneNum(mobile)){
            message.error('请填写正确手机号！')
            return
        }
        setBgColor('#b0b0b0')
        let count = 60
        setText(`从新获取(${count}s)`)
        let timer = setInterval(()=>{
            count--
            if(count <= 0){
                setText('获取验证码')
                setBgColor('#121851')
                clearInterval(timer)
            }else{
                setText(`重新获取(${count}s)`)
            }
        }, 1000)
        axios.post(url.sms, {mobile, type:'juniors'}).then(res => {
        }).catch(err => {
            const {message:err_message} = err.response.data
            message.error(err_message)
            setText('获取验证码')
            setBgColor('#121851')
            clearInterval(timer)
        })
    })

    return (
        <Button
            className={stylesheet.get_code} 
            onClick={handleGetCode}
            style={{background: bg_color}}
            disabled={bg_color == '#b0b0b0'}
        >
            {text}
        </Button>
    )
}

export default () => {
    const defaultBirthday = `${new Date().getFullYear() - 7}-${new Date().getMonth() + 1}-${new Date().getDate()}`

    let [baby_name, setBabyName] = useState('')
    let [birthday, setBirthday] = useState(moment(defaultBirthday))
    let [grade, setGrade] = useState('')
    let [mobile, setMobile] = useState('')
    let [code, setCode] = useState('')

    const handleSubmit = useCallback(()=>{
        const params = {
            baby_name,
            birthday,
            grade,
            mobile,
            code
        }
        params.birthday = moment(params.birthday).format('YYYY-MM-DD')
        if(!params.baby_name){
            message.error('请填写姓名！')
            return
        }
        if(!params.birthday){
            message.error('请填写出生日期！')
            return
        }
        if(!params.grade){
            message.error('请填写在读年级！')
            return
        }
        if(!isPhoneNum(params.mobile)){
            message.error('请填写正确手机号！')
            return
        }
        // if(!params.code){
        //     message.error('请填写验证码！')
        //     return
        // }
        axios.post(url.apply, {
            ...params
        }).then(res => {
            const {message: success_message} = res.data
            message.success(success_message)
        }).catch(err => {
            const {message: err_message} = err.response.data
            message.error(err_message)
        })
    })

    return (
        <div className={stylesheet.form_bg} id='form'>
            <div className={stylesheet.form_container}>
                <div className={stylesheet.form_item}>
                    <label className={stylesheet.label}>孩子姓名</label>
                    <div className={stylesheet.input_container}>
                        <input 
                            value={baby_name}
                            className={stylesheet.input}
                            onChange={e => setBabyName(e.target.value)}
                        />
                    </div>
                </div>
                <div className={stylesheet.form_item}>
                    <label className={stylesheet.label}>出生日期</label>
                    <div className={stylesheet.input_container}>
                        {/* <input className={stylesheet.input} /> */}
                        <DatePicker
                            allowClear={false}
                            value={birthday}
                            className={stylesheet.input}
                            locale={locale} 
                            disabledDate={current => {
                                const before = `${new Date().getFullYear() - 14}-${new Date().getMonth() + 1}-${new Date().getDate()}`
                                const after = `${new Date().getFullYear() - 7}-${new Date().getMonth() + 1}-${new Date().getDate()}`
                                return  current < moment(before, 'YYYY-MM-DD').startOf('day') || current > moment(after, 'YYYY-MM-DD').endOf('day')
                            }}
                            onChange={(date, dateString) => {
                                setBirthday(moment(dateString, 'YYYY-MM-DD'))
                            }}
                        />
                    </div>
                </div>
                <div className={stylesheet.form_item}>
                    <label className={stylesheet.label}>在读年级</label>
                    <div className={stylesheet.input_container}>
                        <Select
                            // className={stylesheet.input}
                            style={{paddingLeft:0,width:'100%',height:40,background:'white',display:'flex',alignItems:'center'}}
                            bordered={false}
                            onChange={e => setGrade(e)}
                            value={grade}
                        >
                            <Option value={'幼儿园在读'} key={'幼儿园在读'}>幼儿园在读</Option>
                            <Option value={'G1'} key={'G1'}>G1</Option>
                            <Option value={'G2'} key={'G2'}>G2</Option>
                            <Option value={'G3'} key={'G3'}>G3</Option>
                            <Option value={'G4'} key={'G4'}>G4</Option>
                            <Option value={'G5'} key={'G5'}>G5</Option>
                            <Option value={'G6'} key={'G6'}>G6</Option>
                        </Select>
                    </div>
                </div>
                <div className={stylesheet.form_item}>
                    <label className={stylesheet.label}>家长电话</label>
                    <div className={stylesheet.input_container}>
                        <input 
                            className={stylesheet.input} 
                            value={mobile}
                            onChange={e => setMobile(e.target.value)}
                        />
                    </div>
                </div>
                {/* <div className={stylesheet.form_item}>
                    <label className={stylesheet.label}>验证码</label>
                    <div className={stylesheet.input_container}>
                        <input 
                            className={stylesheet.input} style={{marginRight:10}} 
                            value={code}
                            onChange={e => setCode(e.target.value)}
                        />
                        <Code mobile={mobile}/>
                    </div>
                </div> */}
                <div className={stylesheet.form_item}>
                    <label className={stylesheet.label}></label>
                    <div className={stylesheet.input_container}>
                        <div className={stylesheet.sub} onClick={handleSubmit}>立 即 咨 询</div>
                    </div>
                </div>
                <p className={stylesheet.form_address}>地址：广东省深圳市南山区华侨城天鹅湖 花园（三期）商业F3层08号商铺</p>
            </div>
        </div>
    )
}