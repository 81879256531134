import axios from 'axios'

const pony_api = '/pony_api'
let url = window.location.href
let url_arr = url.split('.com')
let base_url = url_arr[0]+'.com'
if(base_url.indexOf('localhost') != -1 || base_url.indexOf('pony-dev') != -1){
    base_url = 'https://pony-dev.ponyrunning.com' + pony_api
}else{
	base_url = 'https://pony.ponyrunning.com'
}

axios.defaults.baseURL = base_url
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export default axios