import React from 'react'
import stylesheet from './index.module.less'

export default () => {
    const target = [
        {title: '全科知识储备：', content: '深入浅出，帮助学生形成健全的学术知识体系，紧贴国际学校学习进度。'},
        {title: '跨文化交际素养：', content: '培养全球化视野，有效提升跨文化交流能力。'},
        {title: '自我驱动：', content: '强化深度学习能力，激发自主自立学习的内外驱动力。'},
        {title: '自我管理：', content: '时间管理学习，情绪管理学习，树立目标并有计划学习。'},
        {title: '塑造独立人格：', content: '根据学生特点，制定独立的培养目标，塑造每一个学生独特的人格。'},
        {title: '注重团队协作意识：', content: '塑造团队学习氛围，培养小组合作意识。'},
    ]
    return (
        <div className={stylesheet.container}>
            <div className={stylesheet.left}>
                <div className={stylesheet.bg}></div>
                {/* <img className={stylesheet.img} src='https://parent.ponyrunning.com/activity/20210424/peiyang.png' /> */}
                <div className={stylesheet.img}></div>
                <div className={stylesheet.item}>
                    <div className={stylesheet.title}>掌握高分秘籍： </div>
                    <div className={stylesheet.content}>补基础、讲重点、攻难点、授方法，有效提高成绩，让成长与进步有迹可循。</div>
                </div>
            </div>
            <div className={stylesheet.right}>
                {
                    target.map(item => (
                        <div key={item.title} className={stylesheet.item}>
                            <div className={stylesheet.title}>{item.title}</div>
                            <div className={stylesheet.content}>{item.content}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}