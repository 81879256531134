import './App.css';
import {HashRouter as Router} from 'react-router-dom';
import {useMediaQuery} from 'react-responsive';
import PCApp from './components/PCApp';
import MobileApp from './components/MobileApp';

function App() {

  const isPC = useMediaQuery({
    query: '(min-device-width: 1224.5px)'
  });
  const isMobile = useMediaQuery({
    query: '(max-device-width: 1224.5px)'
  })

  return (
    <Router>
      {isPC && <PCApp />}
      {isMobile && <MobileApp />}
    </Router>
  );
}

export default App;
