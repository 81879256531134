import React, {useEffect, useCallback, useState} from 'react';
import stylesheet from "./index.module.less";

import Banner from './components/Banner'
import Text from './components/Text'
import Form from './components/Form'
import Item from './components/Item'
import Target from './components/Target';
import Team from './components/Team';
import Division from './components/Division'
import Unique from './components/Unique';
import Footer from './components/Footer';
import ItemContent from './components/ItemContent';

const course = [
    {
        left_up:'英语', 
        right_up:<>Core<br />English</>,
        left_down:'读写能力 Core English', 
        right_down:1,
        item_show_content:{
            active:0,
            left_box_title_left:<>读写能力培养<br />English Tutoring</>,
            left_box_title_right:<>精讲课程<br/>Pony Standard<br />Stage</>,
            left_box_content:<><p>“让每个刚过渡到国际学校的孩子，通过对语言的学习轻松的适应国际环境。”</p>
            <p>Pony Junior 读写能力培养课程是一套综合性、系统性、多维度、国际化的核心课程。注重听、说、读、写、词汇、语法、语用等专项技能训练与提高，结合学生心理发展特点，阶段性的进行评估和练习，有助于孩子对应参加剑桥通用英语 KET，PET，TOEFL Primary 和 TOEFL Junior等考试，为在校内外取得优异成绩打好基础。</p></>,
            right_box_title_left:<>语言综合运用 <br/>Language Arts</>,
            right_box_title_right:<>进阶课程<br/>Pony Advanced<br/>Stage</>,
            right_box_content:<><p>“当孩子顺利过渡了国际课程的学习，意味着我们能走得更远，这个阶段更要做好全学科英文提升准备。”</p>
            <p>Pony Junior 语言综合运用课程引进美国原版教材并融合主流国际教学内容，同步契合美国通用课纲 CCSS, 对标美国本土学生的英文水平。以权威的获奖文学作品和独家的 Non-fiction 内容，帮助孩子提高思维能力，学习跨学科内容的掌握更扎实。</p></>,
        }
    },
    {
        left_up:'数学', 
        right_up:<>Core <br /> Math</>, 
        left_down:'思维逻辑 Core Math', 
        right_down:2,
        item_show_content:{
            active:1,
            left_box_title_left:<>思维逻辑培养<br />Math Tutoring</>,
            left_box_title_right:<>精讲课程<br/>Pony Standard<br />Stage</>,
            left_box_content:<><p>“每个刚过渡到国际学校的孩子，精准定位能力水平打好数学基础，在迎面挑战性的同时获得自信。”</p>
            <p>Pony Junior 思维逻辑课基于美国通用课纲 CCSS 和新加坡教学大纲,课程在贴合主流国际学校内容上，特别加入英文数学理解内容，真正帮助孩子看懂题目，做对题目，实现孩子学术能力提升、在校成绩崭露头角。 </p></>,
            right_box_title_left:<>数理逻辑运用<br/>Mathematics Logic </>,
            right_box_title_right:<>进阶课程<br/>Pony Advanced<br/>Stage</>,
            right_box_content:<><p>“当孩子顺利过渡了国际课程的学习，这个阶段要深度掌握阶段性数学知识，让 Math Star 在班上比的同学更快一步。”</p>
            <p>Pony Junior 数理逻辑研习课程基于美国通用课纲 CCSS ,结合我国 9 年义务教育数学课程大纲。让学生在学习数学技巧的同时掌握高阶思维技能，为将来的科学或 STEM 学科做好准备，使孩子能高效精准的应用数学技能，建立深层次的数理思维、培养解决问题能力。</p></>,
        }
    },
    {
        left_up:'科学', 
        right_up:<>Core<br /> Science</>, 
        left_down:'研究能力 Core Science', 
        right_down:3,
        item_show_content:{
            active:2,
            left_box_title_left:<>综合研究学习<br />Science Tutoring</>,
            left_box_title_right:<>精讲课程<br/>Pony Standard<br />Stage</>,
            left_box_content:<><p> “让每个刚过渡到国际学校的孩子，掌握核心科学探索能力成为强大的学习者，为 STEM 成绩提升奠定坚实基础。”</p>
            <p>Pony Junior 综合研究学习课程引进美国原版教材并融合主流国际教学内容，同步契合美国通用课纲 Next Generation Learning Standards, 补充 Evan Moor, Scholastic 和 DK 等不同素材内容。衔接国际教育体系和学习方式，让学生具备非同寻常的创造力和探索精神，实现高效快速的获得科学知识。</p></>,
            right_box_title_left:<>跨学科能力培养<br/>Advanced Science</>,
            right_box_title_right:<>进阶课程<br/>Pony Advanced<br/>Stage</>,
            right_box_content:<><p>“当孩子顺利过渡了国际课程的学习，这个阶段需要进阶科目的帮助，为赢得高水准科学竞赛奠定坚实基础。”</p>
            <p>Pony Junior 跨学科能力培养引进美国原版教材并融合主流国际教学内容，遵循学生学情量身定制学习方案，从高级的一对一辅导到智慧小班精英课堂，有针对性地调整教学方法和节奏，保障孩子的学习效果！全面提高核心学术能力，拓宽学生的全球视野。</p></>,
        }
    },
]

const Distance = (props) => {
    const {height = 0} = props
    return (
        <div style={{height}}></div>
    )
}

const PCApp = (props) => {
    const [item_show_content, setItemShowContent] = useState({})
    let [old_active, setOldActive] = useState('')
    let [show, setShow] = useState(false)

    const handleClick = useCallback(index => {
        const {item_show_content} = course[index]
        const {active: new_active} = item_show_content
        
        if(new_active === old_active){
            if(show){
                window.$('#item_content').slideUp()
                setShow(false)
            }else{
                window.$('#item_content').slideDown()
                setShow(true)
            }
        }else if(old_active === ''){
            window.$('#item_content').slideDown()
            setShow(true)
        }
        setItemShowContent(item_show_content)
        setOldActive(new_active)
        // if(active == old_active){
        //     window.$('#item_content').slideToggle()
        // }else{
        //     setOldActive(active)
        // }
    })

    return (
        <div style={{background:'white'}}>
            <Banner />
            <Distance height={40} />
            <Text
                english='EXPLORE THE WORLD'
                title='以全球视野拓宽成长之路'
                content='Pony Junior 小马快跑青少国际教育，专为 7-14 岁国际学校的学生提供体系完善的学术辅导培优和国际教育咨询等一站式的定制化服务。通过 3H 精英教育理念，融合美国 CCSS、英国 KS 及 IB 教学理念，依托多年一线教学的成功经验与成果，紧跟国内外百余所国际学校的课程深度研发，致力培养面向世界的未来精英，以全球视野拓宽成长之路， 真正无缝接轨国际名校。'
            />
            <Distance height={30} />
            <Form />
            <Distance height={40} />
            <Text
                english='BRILLIANT COURSE'
                title='极课堂 '
                content='根据国际学校课程的阶段难度，科学调整科目 配比与节奏，让学习更高效。'
            />
            <Distance height={40} />
            <div className={stylesheet.item_container}>
                {
                    course.map((item, index) => {
                        return (
                            <Item
                                right_down={item.right_down}
                                right_up={item.right_up}
                                left_down={item.left_down}
                                left_up={item.left_up}
                                key={index}
                                index={index}
                                handleClick={handleClick}
                            />
                        )
                    })
                }
            </div>
            <ItemContent id='item_content' style={{display:'none'}} content={item_show_content} />
            <Distance height={80} />
            <Text
                english='TALENTS TRAINING TARGET'
                title='培养目标'
                content='针对性培养，让优秀的学生更加出类拔萃'
            />
            <Distance height={40} />
            <Target />
            <Distance height={100} />
            <Division/>
            <Distance height={40} />
            <Text
                english='ELITE TEACHING TEAM'
                title={<><p style={{textAlign:'center'}}>严选顶尖师资<br />教学质量有保障</p></>}
            />
            <Team />
            <Distance height={40} />
            <Unique />
            <Distance height={40} />
            <Text
                english='FUTURE READY'
                title={<p style={{textAlign:'center'}}>为未来做好准备<br />国际课程无缝衔接</p>}
                content='全场景闭环教学法，轻松适应国际学校课堂'
            />
            <Footer />
            <div style={{lineHeight: '20px', margin: '30px 0px 0px 20px', textAlign: 'center', fontFamily: 'Source Han Sans SC'}}>
                <img style={{width: 20,height: 20, marginRight: 10}} src="https://parent.ponyrunning.com/pony_official_website/images/gongshang_img%402x.png" />
                上海小马快跑投资管理有限公司<br />投资有风险
                选择需谨慎<br />Copyright&nbsp;©2020.&nbsp;All&nbsp;Rights&nbsp;Reserved&nbsp;<a href='https://beian.miit.gov.cn/'>沪ICP备13012125号-1</a>
            </div>
        </div>
    );
};

export default PCApp;