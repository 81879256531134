import React, {useEffect, useState} from 'react';
// import fastClick from 'fastclick';
import {Button} from 'antd-mobile';
import DatePicker from 'react-mobile-datepicker';
import axios from '../../http/http'
import url from '../../http/url'

import style from './index.module.less';

import Swiper from './components/swiper/swiper';

const logo ='https://parent.ponyrunning.com/activity/20210424/logo.png';
const banner = 'https://parent.ponyrunning.com/activity/20210424/banner.png';
const peiyang = 'https://parent.ponyrunning.com/activity/20210424/peiyang.png';
const submit = 'https://parent.ponyrunning.com/activity/20210424/submit.png';
const submit_active = 'https://parent.ponyrunning.com/activity/20210424/submit_active.png';
const team = 'https://parent.ponyrunning.com/activity/20210424/team.png';
const team_show = 'https://parent.ponyrunning.com/activity/20210424/team_show.png';
const review = 'https://parent.ponyrunning.com/activity/20210424/review.png';
const enrich = 'https://parent.ponyrunning.com/activity/20210424/enrich.png';
const ability = 'https://parent.ponyrunning.com/activity/20210424/ability.png';
const develop = 'https://parent.ponyrunning.com/activity/20210424/develop.png';
const yearn = 'https://parent.ponyrunning.com/activity/20210424/yearn.png';
const menu = 'https://parent.ponyrunning.com/activity/20210424/menu.png'


var $ = require('jquery');
window.$ = $;

const MobileApp = () => {
    let [subActive, setSubActive] = useState(false) // 立即咨询图片active
    let [date_mask, setDateMask] = useState(false) // 日期选择mask
    let [is_disabled, setIsDisabled] = useState(false) // 获取验证码的禁用状态
    let [get_code_text, setGetCodeText] = useState('获取验证码') // 获取验证码 文字

    let [english_height, setEnglishHeight] = useState(0)
    let [math_height, setMathHeight] = useState(0)
    let [sicence_height, setSicenceHeight] = useState(0)

    let [baby_name, setBabyName] = useState('')
    const seven = new Date(new Date().getFullYear() - 7, new Date().getMonth()+1, new Date().getDate()) // 七年前
    let [birthday, setBirthday] = useState(`${seven.getFullYear()}-${seven.getMonth()}-${seven.getDate()}`)
    let [mobile, setMobile] = useState('')
    let [code, setCode] = useState('')
    let [grade, setGrade] = useState('幼儿园在读')
    useEffect(() => {
        // 移动端的适配
        const meta = document.createElement('meta');
        meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no';
        meta.name = 'viewport';
        document.getElementsByTagName('head')[0].appendChild(meta);
        // 移动端点击300ms的延迟
        // fastClick.attach(document.body);
        // 部分安卓系统不支持Promise
        if(!window.Promise){
            var Promise = require('es6-promise').Promise;
            console.log(Promise);
        }

    },[]);

    const isPhoneNum = (str) => { // 验证手机号
        return /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/.test(str)
    }

    const getCode = () => {
        if(isPhoneNum(mobile)){
            setIsDisabled(true)
            let count = 60
            setGetCodeText(`重试(${count}s)`)
            let timer = setInterval(()=>{
                count--
                setGetCodeText(`重试(${count}s)`)
                if(count <= 0){
                    clearInterval(timer)
                    setGetCodeText(`获取验证码`)
                    setIsDisabled(false)
                }
            }, 1000)
            axios.post(url.sms, {
                type: 'juniors',
                mobile
            })
        }else{
            alert('请输入正确手机号!')
        }
    }

    const handleSubmit = () => {
        const params = {
            baby_name,
            birthday,
            mobile,
            code,
            grade
        }
        if(typeof params.birthday == 'string' && params.birthday.indexOf('-') != -1){
            const arr = params.birthday.split('-')
            if(typeof arr[1] == 'string'){arr[1] = arr[1].padStart(2, '0')}
            if(typeof arr[2] == 'string'){arr[2] = arr[2].padStart(2, '0')}
            params.birthday = arr.join('-')
        }
        if(!params.baby_name || !params.birthday || !params.mobile || !params.grade){
            alert('请填写完整信息!')
            return
        }
        if(!isPhoneNum(params.mobile)){
            alert('请输入正确手机号!')
            return
        }
        axios.post(url.apply, {
            ...params
        }).then(res => {
            const {message} = res.data
            alert(message)
        }).catch(err => {
            const {message} = err.response.data
            alert(message)
        })
    }

    const handleJumpStart = () => {
        setSubActive(true)
    }

    const handleJumpEnd = () => {
        setSubActive(false)
        $('html, body').animate({scrollTop: $('#form').offset().top}, 1000)
    }

    const handleSelect = (e) => {
        setGrade(e.target.innerText)
    }

    const handleSelectContainer = () => {
        window.$('#select').slideToggle()
    }
    

    return (
        <div>

            <div className={style.menu_mask} id='mask' onClick={() => {
                window.$('#mask').fadeOut()
            }}>
                <div className={style.menu_item_container} onClick={(e) => {
                    e.stopPropagation()
                }}>
                    <div className={style.hide_mask_img}>
                        <img onClick={() => {
                            window.$('#mask').fadeOut()
                        }} src='https://parent.ponyrunning.com/activity/202104/close.png' style={{width:30}} />
                    </div>
                    <div onClick={() => {
                        window.$('#mask').fadeOut()
                        window.$('html, body').animate({scrollTop: $('#EXPLORE_THE_WORLD').offset().top}, 1000)
                    }} className={style.menu_item}>品牌介绍</div>
                    <div onClick={() => {
                        window.$('#mask').fadeOut()
                        window.$('html, body').animate({scrollTop: $('#BRILLIANT_COURSE').offset().top}, 1000)
                    }} className={style.menu_item}>课程内容</div>
                    <div onClick={() => {
                        window.$('#mask').fadeOut()
                        window.$('html, body').animate({scrollTop: $('#ELITE_TEACHING_TEAM').offset().top}, 1000)
                    }} className={style.menu_item}>师资教材</div>
                    <div onClick={() => {
                        window.$('#mask').fadeOut()
                        window.$('html, body').animate({scrollTop: $('#FUTURE_READY').offset().top}, 1000)
                    }} className={style.menu_item}>教学方法</div>
                    <img onClick={()=>{
                        var u = navigator.userAgent;
                        var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
                        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                        if(isAndroid){
                            window.open('https://www.ponyrunning.com')
                        }else if(isiOS){
                            window.location.href = 'https://www.ponyrunning.com'
                        }
                    }} className={style.website_logo} src='https://parent.ponyrunning.com/activity/202104/pony_logo.png' />
                </div>
            </div>

            <div className={style.nav}>
                <div className={style.up}></div>
                <div className={style.down}>
                    <img className={`${style.logo} wow flip`} src={logo} alt='logo'></img>
                    <div className={`${style.title}`}>
                        <p>小马快跑青少国际教育</p>
                        <p>Pony Junior International Education</p>
                    </div>
                    <img src={menu} className={`${style.menu}`} onClick={() => {
                        window.$('#mask').fadeIn()
                        window.$('#mask').css('display','flex')
                    }} />
                </div>
            </div>
            <div className={`${style.banner} wow fadeInUp`}>
                <img className={style.banner_img} alt='banner' src={banner} />
                <div className={style.banner_text}>
                    Explore<br />the<br />world
                </div>
            </div>
            <div className={style.explore_the_world_container} id='EXPLORE_THE_WORLD'>
                <div className={`${style.title} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">EXPLORE THE WORLD</div>
                <div className={`${style.sub_title} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="100">以全球视野拓宽成长之路 </div>
                <p className={`${style.content} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                Pony Junior 小马快跑青少国际教育，专为 7-14 岁国际学校的学生提供体系完善的学术辅导培优和国际教育咨询等一站式的定制化服务。通过 3H 精英教育理念，融合美国 CCSS、英国 KS 及 IB 教学理念，依托多年一线教学的成功经验与成果，紧跟国内外百余所国际学校的课程深度研发，致力培养面向世界的未来精英，以全球视野拓宽成长之路， 真正无缝接轨国际名校。
                </p>
            </div>
            <div className={style.form_container} id="form">
                <div className={`${style.son_name} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <label className={style.label} htmlFor="name">孩子姓名</label>
                    <input value={baby_name} onChange={e => setBabyName(e.target.value)} className={style.input} id='name' />
                </div>
                <div onClick={_ => setDateMask(true)} className={`${style.son_birthday} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <label className={style.label} htmlFor='birthday'>出生日期</label>
                    <input readOnly value={birthday} className={style.input} id='birthday' />
                    <DatePicker
                        value={new Date(birthday)}
                        isOpen={date_mask}
                        onSelect={(birthday)=>{
                            setBirthday(`${birthday.getFullYear()}-${birthday.getMonth()+1}-${birthday.getDate()}`)
                            setDateMask(false)
                        }}
                        onCancel={()=>{
                            setDateMask(false)
                        }} 
                        headerFormat='YYYY-MM-DD'
                        max={new Date(new Date().getFullYear() - 7, new Date().getMonth(), new Date().getDate())}
                        min={new Date(new Date().getFullYear() - 14, new Date().getMonth(), new Date().getDate())}
                    />
                </div>
                <div onClick={handleSelectContainer} className={`${style.son_grade} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <label className={style.label}>在读年级</label>
                    <input className={style.input} id='grade' readOnly value={grade} />
                    {/* <select className={style.select_style} onChange={e => {
                        setGrade(e.target.value)
                    }}>
                        <option value='幼儿园在读'>幼儿园在读</option>
                        <option value='G1'>G1</option>
                        <option value='G2'>G2</option>
                        <option value='G3'>G3</option>
                        <option value='G4'>G4</option>
                        <option value='G5'>G5</option>
                        <option value='G6'>G6</option>
                    </select> */}
                    <div className={style.select} onClick={handleSelect} id='select'>
                        <div className={grade == '幼儿园在读' ? style.active : ''}>幼儿园在读</div>
                        <div className={grade == 'G1' ? style.active : ''}>G1</div>
                        <div className={grade == 'G2' ? style.active : ''}>G2</div>
                        <div className={grade == 'G3' ? style.active : ''}>G3</div>
                        <div className={grade == 'G4' ? style.active : ''}>G4</div>
                        <div className={grade == 'G5' ? style.active : ''}>G5</div>
                        <div className={grade == 'G6' ? style.active : ''}>G6</div>
                    </div>
                </div>
                <div className={`${style.father_phone} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <label className={style.label} htmlFor='phone'>家长电话</label>
                    <input value={mobile} onChange={e => setMobile(e.target.value)} className={style.input} id='phone' />
                </div>
                {/* <div className={`${style.phone_code} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.code}>
                        <label className={style.label} htmlFor='code'>验证码</label>
                        <input value={code} onChange={e => setCode(e.target.value)} className={style.input} id='code' />
                    </div>
                    <div style={{width:10}}></div>
                    <Button style={{background:`${is_disabled ? 'gray' : ''}`}} disabled={is_disabled} onClick={getCode} className={style.get_code}>{get_code_text}</Button>
                </div> */}
                {/* <div className={style.sub}>立 即 咨 询</div> */}
                <Button className={`${style.sub} wow fadeInUp`} onClick={handleSubmit} data-wow-duration="1s" data-wow-offset="50">立 即 咨 询</Button>
                <div className={`${style.address} wow fadeInUp`}  data-wow-duration="1s" data-wow-offset="50">地址：广东省深圳市南山区华侨城天鹅湖
花园（三期）商业F3层08号商铺</div>
            </div>
            <div style={{background:'#faf9f7'}}>
                <div id='BRILLIANT_COURSE' className={style.explore_the_world_container} style={{width: '70%', paddingBottom:10}}>
                    <div className={`${style.title} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">BRILLIANT COURSE</div>
                    <div className={`${style.sub_title} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="100">极课堂 </div>
                    <p className={`${style.content} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    根据国际学校课程的阶段难度，科学调整科目
    配比与节奏，让学习更高效。
                    </p>
                </div>
                <div className={`${style.course_item} wow fadeInUp`} onClick={()=>{
                    if(english_height == 0){
                        setEnglishHeight(420)
                    }else{
                        setEnglishHeight(0)
                    }
                }} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.course_item_left}>
                        <div className={style.left_up_container}>
                            <div className={style.title}>英语</div>
                            <div className={style.content}>Core<br />English</div>
                        </div>
                        <div className={style.text}>读写能力 Core English</div>
                    </div>
                    <div className={style.course_item_right}>1</div>
                    <div className={style.course_click_show}>点击查看</div>
                </div>
                <div className={style.slide} id="english" style={{height: english_height,overflow:'hidden',padding:english_height == 0 ? 0 : '50px 0'}}>
                    {/* <div className={style.slide_title}>
                        <div className={style.title_left}>
                            读写能力培养<br />English Tutoring 
                        </div>
                        <div className={style.title_right}>
                            精讲课程<br />Pony Standard<br />Stage 
                        </div>
                    </div> */}
                    <Swiper
                        style={{padding: 15}}
                        content={[
                            {text: <div className={style.swiper_text}>
                                <p>
                                    “让每个刚过渡到国际学校的孩子，通过对语言的学习轻松的适应国际环境。” 
                                </p>
                                <p>
                                    Pony Junior 读写能力培养课程是一套综合性、系统性、多维度、国际化的核心课程。注重听、说、读、写、词汇、语法、语用等专项技能训练与提高，结合学生心理发展特点，阶段性的进行评估和练习， 有助于孩子对应参加剑桥通用英语KET，PET，TOEFL Primary 和 TOEFL Junior等考试，为在校内外取得优异成绩打好基础。
                                </p>
                            </div>},
                            {text: <div className={style.swiper_text}>
                                <p>
                                    “当孩子顺利过渡了国际课程的学习，意味着我们能走得更远，这个阶段更要做好全学科英文提升准备。” 
                                </p>
                                <p>
                                    Pony Junior 语言综合运用课程引进美国原版教材并融合主流国际教学内容，同步契合美国通用课纲 CCSS, 对标美国本土学生的英文水平。以权威的获奖文学作品和独家的 Non-fiction 内容，帮助孩子提高思维能力，学习跨学科内容的掌握更扎实。
                                </p>
                            </div>}
                        ]}
                        title={[
                            {left: <>读写能力培养<br />English Tutoring</>, right: <>精讲课程<br />Pony Standard<br />Stage </>},
                            {left: <>语言综合运用<br />Language Arts</>, right: <>进阶课程<br />Pony Advanced<br />Stage</>}
                        ]}
                    />
                </div>
                <div className={`${style.course_item} wow fadeInUp`} onClick={() => {
                    if(math_height == 0){
                        setMathHeight(420)
                    }else{
                        setMathHeight(0)
                    }
                }} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.course_item_left}>
                        <div className={style.left_up_container}>
                            <div className={style.title}>数学</div>
                            <div className={style.content}>Core<br />Math</div>
                        </div>
                        <div className={style.text}>思维逻辑 Core Math</div>
                    </div>
                    <div className={style.course_item_right}>2</div>
                </div>
                <div className={style.slide} id='math' style={{height: math_height,overflow:'hidden',padding:math_height == 0 ? 0 : '50px 0'}}>
                    <Swiper
                        style={{padding: 15}}
                        content={[
                            {text: <div className={style.swiper_text}>
                                <p>
                                    “每个刚过渡到国际学校的孩子，精准定位能力水平打好数学基础，在迎面挑战性的同时获得自信。” 
                                </p>
                                <p>
                                    Pony Junior 思维逻辑课基于美国通用课纲 CCSS 和新加坡教学大纲,课程在贴合主流国际学校内容上，特别加入英文数学理解内容，真正帮助孩子看懂题目，做对题目，实现孩子学术能力提升、在校成绩崭露头角。
                                </p>
                            </div>},
                            {text: <div className={style.swiper_text}>
                                <p>
                                “当孩子顺利过渡了国际课程的学习，这个阶段要深度掌握阶段性数学知识，让 Math Star 在班上比的同学更快一步。” 
                                </p>
                                <p>
                                Pony Junior 数理逻辑研习课程基于美国通用课纲 CCSS ,结合我国 9 年义务教育数学课程大纲。让学生在学习数学技巧的同时掌握高阶思维技能，为将来的科学或 STEM 学科做好准备，使孩子能高效精准的应用数学技能，建立深层次的数理思维、培养解决问题能力。
                                </p>
                            </div>}
                        ]}
                        title={[
                            {left: <>思维逻辑培养 <br />Math Tutoring</>, right: <>精讲课程 <br/>Pony Standard <br/>Stage  </>},
                            {left: <>数理逻辑运用 <br/>Mathematics Logic </>, right: <>进阶课程 <br/>Pony Advanced <br/>Stage</>}
                        ]}
                    />
                </div>
                <div className={`${style.course_item} wow fadeInUp`} onClick={()=>{
                    if(sicence_height == 0){
                        setSicenceHeight(420)
                    }else{
                        setSicenceHeight(0)
                    }
                }} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.course_item_left}>
                        <div className={style.left_up_container}>
                            <div className={style.title}>科学</div>
                            <div className={style.content}>Core<br />Science</div>
                        </div>
                        <div className={style.text}>研究能力 Core Science</div>
                    </div>
                    <div className={style.course_item_right}>3</div>
                </div>
                <div id="sicence" className={style.slide} style={{borderBottomLeftRadius:15, borderBottomRightRadius:15, height: sicence_height,overflow:'hidden',padding:sicence_height == 0 ? 0 : '50px 0'}}>
                    <Swiper
                        style={{padding: 15}}
                        content={[
                            {text: <div className={style.swiper_text}>
                                <p>
                                “让每个刚过渡到国际学校的孩子，掌握核心科学探索能力成为强大的学习者，为 STEM 成绩提升奠定坚实基础。”

                                </p>
                                <p>
                                Pony Junior 综合研究学习课程引进美国原版教材并融合主流国际教学内容，同步契合美国通用课纲 Next Generation Learning Standards, 补充 Evan Moor, Scholastic 和 DK 等不同素材内容。衔接国际教育体系和学习方式，让学生具备非同寻常的创造力和探索精神，实现高效快速的获得科学知识。
                                </p>
                            </div>},
                            {text: <div className={style.swiper_text}>
                                <p>
                                “当孩子顺利过渡了国际课程的学习，这个阶段需要进阶科目的帮助，为赢得高水准科学竞赛奠定坚实基础。”
                                </p>
                                <p>
                                Pony Junior 跨学科能力培养引进美国原版教材并融合主流国际教学内容，遵循学生学情量身定制学习方案，从高级的一对一辅导到智慧小班精英课堂，有针对性地调整教学方法和节奏，保障孩子的学习效果！全面提高核心学术能力，拓宽学生的全球视野。
                                </p>
                            </div>}
                        ]}
                        title={[
                            {left: <>综合研究学习 <br/>Science Tutoring</>, right: <>精讲课程 <br/>Pony Standard <br/>Stage </>},
                            {left: <>跨学科能力培养 <br/>Advanced Science</>, right: <>进阶课程 <br/>Pony Advanced <br/>Stage</>}
                        ]}
                    />
                </div>
                <div className={style.explore_the_world_container} style={{width: '70%', paddingBottom:10}}>
                    <div className={`${style.title} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">TALENTS TRAINING TARGET</div>
                    <div className={`${style.sub_title} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">培养目标 </div>
                    <p className={`${style.content} wow fadeInUp`}>
                    针对性培养，让优秀的学生更加出类拔萃
                    </p>
                </div>
                <div className={`${style.peiyang_container} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.left_up}></div>
                    <img className={style.peiyang_img} src={peiyang} alt='peiyang' />
                </div>
                <div className={`${style.peiyang_title_content} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.peiyang_title}>掌握高分秘籍：</div>
                    <div className={style.peiyang_content}>补基础、讲重点、攻难点、授方法，有效提高成绩，让成长与进步有迹可循。</div>
                </div>
                <div className={`${style.peiyang_title_content} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.peiyang_title}>跨文化交际素养：</div>
                    <div className={style.peiyang_content}>培养全球化视野，有效提升跨文化交流能力。</div>
                </div>
                <div className={`${style.peiyang_title_content} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.peiyang_title}>全科知识储备：</div>
                    <div className={style.peiyang_content}>深入浅出，帮助学生形成健全的学术知识体系，紧贴国际学校学习进度。</div>
                </div>
                <div className={`${style.peiyang_title_content} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.peiyang_title}>自我驱动：</div>
                    <div className={style.peiyang_content}>强化深度学习能力，激发自主自立学习的内外驱动力。</div>
                </div>
                <div className={`${style.peiyang_title_content} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.peiyang_title}>自我管理：</div>
                    <div className={style.peiyang_content}>时间管理学习，情绪管理学习，树立目标并有计划学习。</div>
                </div>
                <div className={`${style.peiyang_title_content} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.peiyang_title}>塑造独立人格：</div>
                    <div className={style.peiyang_content}>根据学生特点，制定独立的培养目标，塑造每一个学生独特的人格。</div>
                </div>
                <div className={`${style.peiyang_title_content} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.peiyang_title}>注重团队协作意识：</div>
                    <div className={style.peiyang_content}>塑造团队学习氛围，培养小组合作意识。</div>
                </div>
                <div className={style.call}>
                    <div className={`${style.call_title} wow fadeInDown`} data-wow-duration="1s" data-wow-offset="50">
                        <p className={style.call_up}>权威测评 快速提分</p>
                        <p className={style.call_down}>众多海外院校鼎力推荐，顶尖学府专用测评系统</p>
                    </div>
                    <div className={`${style.submit} wow rubberBand`} data-wow-duration="1s" data-wow-offset="50">
                        <img onTouchStart={handleJumpStart} onTouchEnd={handleJumpEnd} src={subActive ? submit_active : submit} style={{height: '100%'}} alt='submit'/>
                    </div>
                </div>
                <div id='ELITE_TEACHING_TEAM' className={style.explore_the_world_container} style={{width: '70%', paddingBottom:10}}>
                    <div className={`${style.title} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">ELITE TEACHING TEAM</div>
                    <div style={{textAlign: 'center'}} className={`${style.sub_title} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">严选顶尖师资<br />教学质量有保障</div>
                </div>
                <div className={`${style.team} wow bounceInLeft`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.gradients}>
                        <img src={team} alt='team' />
                    </div>
                </div>
                <div className={style.team_five}>
                    <div className={style.team_five_left}>
                        <div className={`${style.team_item} wow bounceInLeft`} data-wow-duration="1s" data-wow-offset="50">
                            <div className={style.team_item_pont}></div>
                            <div className={style.team_item_text}>网罗全球名校精英<br /><span className={style.team_english}>Excellent teachers</span></div>
                        </div>
                        <div className={`${style.team_item} wow bounceInLeft`} data-wow-duration="1s" data-wow-offset="50">
                            <div className={style.team_item_pont}></div>
                            <div className={style.team_item_text}>专业教学技巧<br /><span className={style.team_english}>Interdisciplinary knowledge</span></div>
                        </div>
                        <div className={`${style.team_item} wow bounceInLeft`} data-wow-duration="1s" data-wow-offset="50">
                            <div className={style.team_item_pont}></div>
                            <div className={style.team_item_text}>胜人一筹的好评率<br /><span className={style.team_english}>Excelling positive reviews</span></div>
                        </div>
                    </div>
                    <div className={style.team_five_right}>
                        <div className={`${style.team_item} wow bounceInRight`} data-wow-duration="1s" data-wow-offset="50">
                            <div className={style.team_item_pont}></div>
                            <div className={style.team_item_text}>长期教学经验<br /><span className={style.team_english}>Long term teaching</span></div>
                        </div>
                        <div className={`${style.team_item} wow bounceInRight`} data-wow-duration="1s" data-wow-offset="50">
                            <div className={style.team_item_pont}></div>
                            <div className={style.team_item_text}>完善的备课&教案<br /><span className={style.team_english}>Teaching Plan and Skills</span></div>
                        </div>
                    </div>
                </div>
                <div style={{background: '#121851'}}>
                    <div id='UNIQUE_TEACHING_RESOURCES' className={style.explore_the_world_container} style={{width: '90%', paddingBottom:10}}>
                        <div className={`${style.title} wow fadeInUp`} style={{color:'#FFFFFF'}} data-wow-duration="1s" data-wow-offset="50">UNIQUE TEACHING RESOURCES</div>
                        <div style={{textAlign: 'center', color: '#FFFFFF'}} className={`${style.sub_title} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">独家自研教材<br />国际课程无缝衔接</div>
                        <p className={`${style.content} wow fadeInUp`} style={{color:'#FFFFFF'}} data-wow-duration="1s" data-wow-offset="50">
                        主流国际课程。整编系统严谨、易学易用的系列化独有教材。与学生水平发展更匹配的教学节奏，由浅入深的螺旋式的教材编排，循序渐进地夯实孩子们的学术能力基础，培养深层解决问题的思维能力。
                        </p>
                    </div>
                    <div className={`${style.team_show_container} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                        <img className={style.team_show} src={team_show} alt='team' />
                    </div>
                </div>
                <div className={style.explore_the_world_container} style={{width: '90%', paddingBottom:10}} id='FUTURE_READY'>
                    <div className={`${style.title} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">FUTURE READY</div>
                    <div style={{textAlign: 'center'}} className={`${style.sub_title} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">为未来做好准备<br />国际课程无缝衔接</div>
                    <p className={`${style.content} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    全场景闭环教学法，轻松适应国际学校课堂
                    </p>
                </div>
                <div className={`${style.footer_item_container} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.footer_img_container}>
                        <img style={{width:'90%'}} className={style.footer_img} src={review} alt='review' />
                    </div>
                    <div className={style.footer_text_container}>
                        <div className={style.footer_title}>Review Learning 温故知新：</div>
                        <div className={style.footer_text}>课前预习+课中强化+课后练习三重学习结构，巩固基础温故知新，完成专属学习笔记。</div>
                    </div>
                </div>
                <div className={`${style.footer_item_container} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.footer_img_container}>
                        <img style={{height: '100%'}} className={style.footer_img} src={enrich} alt='enrich' />
                    </div>
                    <div className={style.footer_text_container}>
                        <div className={style.footer_title} style={{whiteSpace: 'nowrap'}}>Enrich Teaching 赋能教学：</div>
                        <div className={style.footer_text}>模块化系统教学，专注深化领域，为学生匹配精准的指导建议，老师更专业，学生提升更快。</div>
                    </div>
                </div>
                <div className={`${style.footer_item_container} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.footer_img_container}>
                        <img style={{height: '100%'}} className={style.footer_img} src={ability} alt='ability' />
                    </div>
                    <div className={style.footer_text_container}>
                        <div className={style.footer_title} style={{whiteSpace: 'nowrap'}}>Ability Testing 能力测试：</div>
                        <div className={style.footer_text}>入学能力测试+课前课中定期测试+周期性评估测试，从量化的角度把控学生的学习进程。</div>
                    </div>
                </div>
                <div className={`${style.footer_item_container} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.footer_img_container}>
                        <img style={{width: '100%'}} className={style.footer_img} src={develop} alt='develop'  />
                    </div>
                    <div className={style.footer_text_container}>
                        <div className={style.footer_title} style={{whiteSpace: 'nowrap'}}>Develop Tracking 成长记录：</div>
                        <div className={style.footer_text}>实时反馈学习情况与指导建议，让进步可见。关注家校互联，形成双向良性沟通，定期输出学习总结报告，共同助力学生学业。</div>
                    </div>
                </div>
                <div className={`${style.footer_item_container} wow fadeInUp`} data-wow-duration="1s" data-wow-offset="50">
                    <div className={style.footer_img_container}>
                        <img style={{height: '100%'}} className={style.footer_img} src={yearn} alt='yearn' />
                    </div>
                    <div className={style.footer_text_container}>
                        <div className={style.footer_title}>
                            Yearn For Improvement
                        </div>
                        <div className={style.footer_title} style={{marginTop: 5, width: 80}}>渴望进步：
                        </div>
                        <div className={style.footer_text}>实时反馈学习情况与指导建议，让进步可见。关注家校互联，形成双向良性沟通，定期输出学习总结报告，共同助力学生学业。</div>
                    </div>
                </div>


            </div>
            <div style={{lineHeight: '20px', marginTop: 10, fontFamily: 'Source Han Sans SC',textAlign:'center'}}>
                <img style={{width: 20,height: 20, marginRight: 10}} src="https://parent.ponyrunning.com/pony_official_website/images/gongshang_img%402x.png" />
                上海小马快跑投资管理有限公司<br />投资有风险
                选择需谨慎<br />Copyright&nbsp;©2020.&nbsp;All&nbsp;Rights&nbsp;Reserved&nbsp;<a href='https://beian.miit.gov.cn/'>沪ICP备13012125号-1</a>
            </div>
            <div style={{height:80}}></div>
            <div className={style.fixed} onClick={() => {
                $('html, body').animate({scrollTop: $('#form').offset().top}, 1000)
            }}>立 即 咨 询</div>
        </div>
    );
};
export default MobileApp;