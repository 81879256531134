import React from 'react'
import stylesheet from './index.module.less'

const Item = (props) => {
    const {content:{title_left, title_right, content}, angle_position = ''} = props
    return (
        <div className={stylesheet.item_container}>
            <div 
                className={stylesheet.angle_container} 
                style={{
                    justifyContent: angle_position == 'left' ? 'flex-start' : 'flex-end'
                }} 
            >
                {
                    angle_position == '' ?
                    null :
                    <div className={stylesheet.item_angle}></div>
                }
            </div>
            <div className={stylesheet.bg}>
                <div className={stylesheet.title_container}>
                    <div className={stylesheet.title_left}>{title_left}</div>
                    <div className={stylesheet.title_right}>{title_right}</div>
                </div>
                <div className={stylesheet.content}>{content}</div>
            </div>
        </div>
    )
}

export default props => {
    const {
        content:{
            left_box_title_left,
            left_box_title_right, 
            left_box_content,
            right_box_title_left,
            right_box_title_right,
            right_box_content,
            active
        },
        style,
        id
    } = props
    const left = {
        title_left: left_box_title_left,
        title_right: left_box_title_right, 
        content: left_box_content
    }
    const right = {
        title_left: right_box_title_left,
        title_right: right_box_title_right,
        content: right_box_content
    }
    let left_angle = ''
    let right_angle = ''
    switch(active){
        case 0:
            left_angle = 'left'
            right_angle = ''
            break;
        case 1:
            left_angle = 'right'
            right_angle = 'left'
            break;
        case 2:
            left_angle = ''
            right_angle = 'right'
            break;
        default :
            break
    }
    return (
        <div id={id} style={style} className={stylesheet.container}>
            <Item angle_position={left_angle} content={left} />
            <Item angle_position={right_angle} content={right} />
        </div>
    )
}